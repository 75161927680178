<template>
  <section class="NotFound">
    <p>
      <span ref="left">4</span>
      <span>0</span>
      <span ref="right">4</span>
    </p>
  </section>
</template>

<script>

import ticker from '@/managers/TickManager'
import input from '@/managers/InputManager'

export default {
  name: 'NotFound',
  props: {},
  components: {},
  data() {
    return {

    }
  },
  mounted() {
    this.tickId = ticker.on( this.onTick )

    this.shift = { x: 0, y: 0 }
  },
  beforeDestroy() {
    ticker.off( this.tickId )
  },
  methods: {
    onTick() {

      const { normal } = input.latest.data
      const xShift = 10 * ( normal.x * 2 - 1 )
      const yShift = 50 * ( normal.y * 2 - 1 )

      if ( normal.x === 0 && normal.y === 0 ) return

      this.shift.x += ( xShift - this.shift.x ) * 0.02
      this.shift.y += ( yShift - this.shift.y ) * 0.02

      this.$refs.left.style.transform = `translate3d( ${ this.shift.x }%, ${ this.shift.y }%, 0 )`
      this.$refs.right.style.transform = `translate3d( ${ this.shift.x * -1 }%, ${ this.shift.y * -1 }%, 0 )`
    }
  }
}

</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  .NotFound {

    position: relative;

    p {

      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      @include fontBlack();
      color: $color-violet;
      font-size: 16vw;
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      span {

        display: block;

      }

    }
  }

</style>
